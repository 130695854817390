export class StorageManager {

    static get(name) {
        let data = localStorage.getItem(name);

        if (data == null) {
            throw "Item not found in storage!"
        }

        let decoded;

        try {
            decoded = atob(data);
        } catch (e) {
            throw "Could not base64 decode cookie!"
        }

        let storageObject

        try {
            storageObject = JSON.parse(decoded);
        } catch (e) {
            throw "Could not JSON parse cookie!"
        }

        if (storageObject !== Object(storageObject)) {
            throw "Cookie is not an object!"
        }

        return storageObject;
    }

    static set(name, value) {
        let stringified = JSON.stringify(value);
        let base64 = btoa(stringified);

        localStorage.setItem(name, base64);
    }

    static delete(name) {
        localStorage.removeItem(name);
    }

}