const envs = {
    LOCAL: 'local',
    DEV: 'dev',
    STAGING: 'staging',
}

let env;

/**
 * Contains all environment variables
 */
export const Environment = {

    get API_BASE_URL() {
        switch (env) {
            case envs.LOCAL:
                return "ws://localhost:8114/ws/";
            case envs.DEV:
                return "wss://api.post.misc.ubiqu-dev.com/ws/";
            case envs.STAGING:
            default:
                return "wss://api.post.misc.ubiqu-staging.com/ws/";
        }
    },

    get APPLICATION_TITLE() {
        switch (env) {
            case envs.LOCAL:
                return "Post Service - Local";
            case envs.DEV:
                return "Post Service - Dev";
            case envs.STAGING:
            default:
                return "Post Service - Staging";
        }
    },

}

// All down here is to detect the environment
let hostname = window.location.hostname;

if (hostname === "localhost") {
    env = envs.LOCAL;
} else {
    let envInHostname = hostname.split(".")[2].replace("ubiqu", "");

    switch (envInHostname) {
        case "-dev":
            env = envs.DEV;
            break;
        case "-staging":
            env = envs.STAGING;
            break;
    }

}