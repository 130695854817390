export default function dateFormatted(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    if (isNaN(date.getUTCDay())) {
        return "Date unknown"
    }

    return date.toDateString() + ", " + date.toLocaleTimeString();
}