import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({

    state: {
        connectionState: null,
        postData: [],
    },

    strict: false,

    mutations: {

        addPostData(state, postData) {
            state.postData.push(postData);
        },

        updatePostData(state, postData) {
            state.postData = postData;
        },

        updateConnectionState(state, connectionState) {
            state.connectionState = connectionState;
        },

    },

    getters: {

    },

    actions: {

    }

});

export default store;