<template>

        <v-sheet
            min-height="100%"
            class="pa-4 mt-4"
            rounded="lg"
        >

            <v-row no-gutters>
                <span class="text-h5">Received POST requests</span>

                <v-spacer/>

            </v-row>

            <v-data-table
                :footer-props="footerConfiguration"
                :headers="headers"
                :items="postData"
                sort-by="created"
                :sort-desc="true"
                no-data-text="No POST requests received yet"
            >

                <template v-slot:[`item.body`]="{ item }">
                    <div class="my-2">{{ item.body }}</div>
                </template>

                <template v-slot:[`item.path`]="{ item }">
                    <div class="my-2">{{ item.path }}</div>
                </template>

                <template v-slot:[`item.created`]="{ item }">
                    <div class="my-2">{{ item.createdFormatted }}</div>
                </template>

            </v-data-table>

            <v-row no-gutters>

                <v-spacer/>

                <v-btn @click="onRemoveAllClicked" color="red" class="white--text" :disabled="postData.length === 0">
                    <v-icon class="mr-2">mdi-trash-can</v-icon>
                    Remove all
                </v-btn>

            </v-row>

        </v-sheet>

</template>

<script>
import API from "../utils/api";

export default {
    name: "ReferenceView",

    data: () => ({
        footerConfiguration: {
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
        },
        headers: [
            {text: "Created", align: 'start', sortable: true, value: 'created', width: "20%",
                sort: (a, b) =>  a - b
            },
            {text: "Path", align: 'start', sortable: false, value: 'path'},
            {text: "Data", align: 'start', sortable: false, value: 'body'},
        ]
    }),

    computed: {

        postData() {
            return this.$store.state.postData;
        }

    },

    methods: {

        onRemoveAllClicked() {
            API.removeAllData();
        },

    },

    created() {
        API.receiveAllData();
    }

}
</script>

<style>



</style>