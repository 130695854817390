<template>

    <v-navigation-drawer
        :value="drawer"
        @input="onDrawerInput"
        absolute
        temporary
        right
    >

        <v-toolbar>
            <v-toolbar-title class="font-weight-medium">
                Settings
            </v-toolbar-title>

            <v-spacer/>

            <v-btn icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-toolbar>

        <!-- Theming settings -->
        <v-container class="px-4">

            <p class="font-weight-bold">Theming</p>

            <v-switch
                inset
                color="primary"
                v-model="darkModeSetting"
                label="Dark mode"
            ></v-switch>

        </v-container>

        <v-divider/>

        <!-- Behaviour settings -->
        <v-container class="px-4">

            <p class="font-weight-bold">Behaviour</p>

            <v-switch
                inset
                color="primary"
                v-model="autoConnectSetting"
                label="Auto connect"
            >
                <template v-slot:label>
                    Auto connect
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon right v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <span style="min-width: 75px">If this setting is turned on, the application will auto connect to the last reference you used</span>
                    </v-tooltip>
                </template>
            </v-switch>

        </v-container>

    </v-navigation-drawer>

</template>

<script>
import {ConfigManager} from "../utils/config";

export default {
    name: "SettingsNavbar",

    model: {
        prop: "drawer"
    },

    props: {
        drawer: Boolean,
    },

    data: () => ({
        autoConnectSetting: false,
        darkModeSetting: false,
    }),

    watch: {

        autoConnectSetting(value) {
            ConfigManager.setAutoConnectConfig(value);
        },

        darkModeSetting(value) {
            ConfigManager.setDarkModeConfig(value);
            this.$vuetify.theme.dark = value;
        }

    },

    methods: {

        onDrawerInput(event) {
            this.$emit("input", event);
        },

    },

    created() {
        this.autoConnectSetting = ConfigManager.getAutoConnectConfig().use;
        this.darkModeSetting = ConfigManager.getDarkModeConfig().use;
    }

}
</script>

<style scoped>

</style>