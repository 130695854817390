<template>

    <v-app>

        <v-app-bar
            app
            color="primary"
            dark
            flat
        >

            <v-container class="py-0 fill-height px-0 pl-sm-3">

                <v-img
                    src="./assets/ubiqu_white.svg"
                    max-height="30"
                    max-width="30"
                    contain
                    class="mr-4"
                ></v-img>

                <v-toolbar-title class="font-weight-bold">
                    {{ applicationTitle }}
                </v-toolbar-title>

                <v-spacer/>

                <v-btn icon large @click="settingsDrawer = true">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>

            </v-container>

        </v-app-bar>

        <settings-navbar
            v-model="settingsDrawer"
        ></settings-navbar>

        <v-main :class="{'grey lighten-3': !$vuetify.theme.dark}" >
            <v-container>
                <v-row>
                    <v-col cols="12" class="mt-12">
                        <connection-view></connection-view>
                    </v-col>
                    <v-fade-transition>
                        <v-col cols="12" v-if="isConnected">
                            <reference-view></reference-view>
                        </v-col>
                    </v-fade-transition>
                </v-row>
            </v-container>
        </v-main>

    </v-app>

</template>

<script>
import ReferenceView from "./views/ReferenceView";
import SettingsNavbar from "./components/SettingsNavbar";
import {ConfigManager} from "./utils/config";
import ConnectionView from "./views/ConnectionView";
import {Environment} from "./utils/environment";

export default {
    name: "App",

    components: {
        ConnectionView,
        SettingsNavbar,
        ReferenceView,
    },

    data: () => ({
        applicationTitle: "",
        settingsDrawer: false,
    }),

    computed: {

        isConnected() {
            return this.$store.state.connectionState === WebSocket.OPEN;
        }

    },

    methods: {

    },

    created() {
        this.$vuetify.theme.dark = ConfigManager.getDarkModeConfig().use;
        this.applicationTitle = Environment.APPLICATION_TITLE;
    }
}
</script>

<style scoped>

</style>