import {Environment} from "./environment";
import store from "../store";
import PostData from "./classes/postdata";

let webSocket;
let currentReference;

function setWebSocketListeners() {
    // Connected to the websocket!
    webSocket.onopen = function () {
        console.log("Connection opened");
        store.commit("updateConnectionState", webSocket.readyState);
    };

    // Received data from the websocket
    webSocket.onmessage = function (event) {
        try {
            let response = JSON.parse(event.data);
            APIHandler.handleResponse(response);
        } catch (e) {
            console.error(e);
        }
    };

    // Connection errors from the websocket
    webSocket.onerror = function (event) {
        console.log("Received errors");
        console.log(event);
    };

    // Connection closed
    webSocket.onclose = function() {
        console.log("Connection closed");
        store.commit("updateConnectionState", webSocket.readyState);
    }
}


const APIHandler = {

    connect(reference) {
        currentReference = reference;
        let url = Environment.API_BASE_URL + reference + "/";
        let protocols = [];

        webSocket = new WebSocket(url, protocols);
        store.commit("updateConnectionState", webSocket.readyState);
        setWebSocketListeners();
    },

    delete() {
        let message = {
            command: "delete"
        };

        webSocket.send(JSON.stringify(message));
    },

    get() {
        let message = {
            command: "get"
        };

        webSocket.send(JSON.stringify(message));
    },

    handleResponse(response) {
        if (response.request) {
            this.handleRequest(response);
        } else if (response.action) {
            this.handleAction(response);
        }
    },

    handleAction(response) {
        switch (response.action) {
            case "post": {
                store.commit("addPostData", PostData.parseObject(response.result));
                break;
            }
            default: {
                console.log(response);
            }
        }
    },

    handleRequest(response) {
        switch (response.request) {
            case "get": {
                let postData = [];
                for (let data of response.result) {
                    postData.push(PostData.parseObject(data));
                }
                store.commit("updatePostData", postData);
                break;
            }
            case "delete": {
                store.commit("updatePostData", []);
                break;
            }
            default: {
                console.log(response);
            }
        }
    },

    close() {
        webSocket.close();
    },

}

const API = {

    close() {
        APIHandler.close();
    },

    connect(reference, connectionPromise) {
        // If the websocket is already set, close it before reconnecting
        if (webSocket) {
            APIHandler.close();
        }

        APIHandler.connect(reference);
    },

    receiveAllData() {
        APIHandler.get();
    },

    removeAllData() {
        APIHandler.delete();
    },

}

export default API;