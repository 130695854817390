<template>

    <v-sheet rounded="lg">

        <v-card class="pa-4">

            <p class="text-h5">Reference</p>

            <v-text-field
                v-model="connectionReference"
                :disabled="isCreatingConnection"
                :error-messages="errorMessage"
                @keypress.enter="createConnection"
                label="Name"
                ref="reference"
                :rules=referenceRules
            ></v-text-field>

            <v-card-actions class="px-0">

                <v-spacer></v-spacer>

                <v-btn
                    :disabled="isCreatingConnection"
                    color="green" class="white--text" @click="createConnection"
                >
                    Connect
                </v-btn>

                <v-btn :disabled="!isConnected" color="red" class="white--text" @click="closeConnection">
                    Disconnect
                </v-btn>

            </v-card-actions>

            <v-progress-linear v-if="isCreatingConnection" indeterminate color="primary"/>
        </v-card>

    </v-sheet>


</template>

<script>
import API from "../utils/api";
import {ConfigManager} from "../utils/config";

export default {
    name: "ConnectionView",

    data: () => ({
        autoConnect: false,
        connectionReference: "",
        errorMessage: "",
        loaded: false,
        referenceRules: [
            v => !!v || "Name is required",
            v => (v && v.trim() === v) || "Name should not start or end with spaces",
        ],
    }),

    computed: {

        isCreatingConnection() {
            return this.$store.state.connectionState === WebSocket.CONNECTING;
        },

        isConnected() {
            return this.$store.state.connectionState === WebSocket.OPEN;
        },

    },

    watch: {

        isCreatingConnection(value) {
            if (!value && !this.isConnected) {
                this.errorMessage = "Could not connect with reference";
            } else {
                this.errorMessage = "";
            }
        },

        isConnected(value) {
            if (!value) {
                this.errorMessage = "Lost connection with reference";
            }
        }

    },

    methods: {

        createConnection() {
            if (!this.$refs.reference.validate()) {
                return;
            }

            ConfigManager.setLastReference(this.connectionReference);
            API.connect(this.connectionReference)
        },

        closeConnection() {
            API.close()
        },

    },

    created() {
        this.connectionReference = ConfigManager.getLastReference().reference;
        this.autoConnect = ConfigManager.getAutoConnectConfig().use;
    },

    mounted() {
        setTimeout(() => {
            this.loaded = true;

            if (this.autoConnect && this.connectionReference.length > 0) {
                this.createConnection();
            }
        }, 500);
    },

}
</script>

<style scoped>

</style>