import {StorageManager} from "./storage";

/**
 * Class that is responsible for managing all client side configuration
 */
export class ConfigManager {

    /**
     * Wrapper object that is stored in the local storage. Contains all settings
     * @type {string}
     */
    static CONFIG_ATTRIBUTE = "cl_cfg";

    static LAST_REFERENCE_CONFIG = "LAST_REFERENCE_CONFIG";
    static DARK_MODE_CONFIG = "DARK_MODE_CONFIG";
    static AUTO_CONNECT_CONFIG = "AUTO_CONNECT_CONFIG";

    static setDarkModeConfig(useDarkMode) {
        let config = {use: useDarkMode};
        this.__updateConfigParam(this.DARK_MODE_CONFIG, config);
    }

    static getDarkModeConfig() {
        let config = this.__getConfig();

        // If object is stored, return the stored value.
        if (Object.prototype.hasOwnProperty.call(config, this.DARK_MODE_CONFIG)) {
            return config[this.DARK_MODE_CONFIG]
        }

        // Else return a default object.
        return {
            use: false
        };
    }

    static setAutoConnectConfig(autoConnect) {
        let config = {use: autoConnect};
        this.__updateConfigParam(this.AUTO_CONNECT_CONFIG, config);
    }

    static getAutoConnectConfig() {
        let config = this.__getConfig();

        // If object is stored, return the stored value.
        if (Object.prototype.hasOwnProperty.call(config, this.AUTO_CONNECT_CONFIG)) {
            return config[this.AUTO_CONNECT_CONFIG]
        }

        // Else return a default object.
        return {
            use: true
        };
    }

    static setLastReference(reference) {
        let config = {reference: reference};
        this.__updateConfigParam(this.LAST_REFERENCE_CONFIG, config);
    }

    static getLastReference() {
        let config = this.__getConfig();

        // If object is stored, return the stored value.
        if (Object.prototype.hasOwnProperty.call(config, this.LAST_REFERENCE_CONFIG)) {
            return config[this.LAST_REFERENCE_CONFIG]
        }

        // Else return a default object.
        return {
            reference: ""
        };
    }


    /**
     * Updates a specific parameter of the config object.
     * @param paramName String representing the parameter of the object
     * @param paramData Data which will be placed in the object's parameter.
     */
    static __updateConfigParam(paramName, paramData) {
        let config = this.__getConfig();

        config[paramName] = paramData;

        this.__updateConfig(config);
    }

    /**
     * Update the config object.
     * @param newConfig The new config object.
     */
    static __updateConfig(newConfig) {
        StorageManager.set(this.CONFIG_ATTRIBUTE, newConfig);
    }

    /**
     * Returns the config object which is stored in an b64-encoded JSON object cookie.
     * @returns {Object|{}}
     */
    static __getConfig() {
        let config;

        try {
            config = StorageManager.get(this.CONFIG_ATTRIBUTE);
        } catch(e) {
            return {};
        }

        return config;
    }
}