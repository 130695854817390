import dateFormatted from "../date";

export default class PostData {

    constructor(uuid, body, path, created) {
        this.uuid = uuid;
        this.body = body;
        this.path = path;
        this.created = new Date(created);
        this.createdFormatted = dateFormatted(created);
    }

    /**
     * Parses object received from API
     * @param apiObject
     * @returns {PostData}
     */
    static parseObject(apiObject) {
        return new PostData(
            apiObject.uuid,
            apiObject.body,
            apiObject.path,
            apiObject.created,
        )
    }

}